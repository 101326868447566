import './managementFinance.css'


import Revenue_CategoryWiseModal from "../../components/modal/managementFinanceModal/revenue_CategoryWiseModal";
import TotalSales_MonthWiseModal from "../../components/modal/managementFinanceModal/totalSales_MonthWiseModal";
import TotalCollection_MonthWiseModal from "../../components/modal/managementFinanceModal/totalCollection_MonthWiseModal";
import TotalCollection_CategoryWiseModal from "../../components/modal/managementFinanceModal/totalCollection_CategoryWiseModal";

import CustomerOutstandingModal from "../../components/modal//managementFinanceModal/customerOutstandingModal";
import SupplierOutstandingModal from "../../components/modal/managementFinanceModal/supplierOutstandingModal";
import BankBalanceModal from "../../components/modal/managementFinanceModal/bankBalanceModal";
import ProductivityModal from "../../components/modal/managementFinanceModal/productivityModal";


export default function ManagementFinance() {


    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-2 rounded contentLeveApproval form__body">
                    
                    <div className="row mt-1">
                        <div className='col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3  text-start'>
                        </div>
                        <div className="col-8 col-sm-8 col-md-4 col-md-4 col-lg-4 col-xl-6 col-xxl-6 text-center">
                            <h1 className='headerName'>Management Finance</h1>
                        </div>
                        <div className="col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3 text-center"></div>
                    </div>

                    <div className='row mt-0 mt-sm-0 mt-md-0 mt-lg-5 mt-xl-5 mt-xxl-5'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2'>
                            <div className="row p-5 d-flex justify-content-center" >
                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <Revenue_CategoryWiseModal />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <TotalSales_MonthWiseModal />
                                    </div>
                               </div>

                               <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <TotalCollection_MonthWiseModal />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <TotalCollection_CategoryWiseModal />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <CustomerOutstandingModal />
                                    </div>
                                </div>
                            </div>

                            <div className="row pe-5 ps-5 d-flex justify-content-center">                                
                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <SupplierOutstandingModal />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <BankBalanceModal />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <ProductivityModal />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}