import { Chart } from "react-google-charts";

import './managementOperation.css'


import CaseReceivedModal from "../../components/modal/managementOperationModal/caseReceivedModal";
import PickupRequestModal from "../../components/modal/managementOperationModal/pickupRequestModal";
import DeliveryPersonModal from "../../components/modal/managementOperationModal/deliveryPersonModal";

export default function ManagementOperation() {


    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-2 rounded contentLeveApproval form__body">
                    
                    <div className="row mt-1">
                        <div className='col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3  text-start'>
                        </div>
                        <div className="col-8 col-sm-8 col-md-4 col-md-4 col-lg-4 col-xl-6 col-xxl-6 text-center">
                            <h1 className='headerName'>Management Operation</h1>
                        </div>
                        <div className="col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3 text-center"></div>
                    </div>

                    <div className='row mt-0 mt-sm-0 mt-md-0 mt-lg-5 mt-xl-5 mt-xxl-5'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2 mt-5'>
                            <div className="row p-5 d-flex justify-content-center align-items-center">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <CaseReceivedModal/>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <DeliveryPersonModal/>
                                    </div>
                               </div>

                               <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-2">
                                    <div class="card rounded rounded-5">
                                        <PickupRequestModal/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}