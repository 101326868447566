import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import { usersAPI } from '../../api/usersAPI';
import { Routes, Route } from 'react-router-dom';

import './navigation.css';

import LeaveApproval from '../../pages/leaveApproval';
import EmployeeLeaveDetails from '../../pages/employeeLeaveDetails'
import LeaveRequest from '../../components/modal/leaveRequest';

import ManagementOperation from '../../pages/managementOperation'
import ManagementFinance from '../../pages/managementFinance'

import ManagementOperationImg from '../../assets/images/ManagementOperation.png';
import FinanceImg from '../../assets/images/Finance.png';

export default function Navigation({ logOut }) {
    let isFirstRender = useRef(true);

    //const [isAllowLeaveReq, setIsAllowLeaveReq] = useState(false);
    //const [isAllowLeaveApproval, setIsAllowLeaveApproval] = useState(false);
    //const [isAllowEmployeeLeaveDetails, setIsAllowEmployeeLeaveDetails] = useState(false);

    //useEffect(() => {
    //    if (isFirstRender) {
    //        isFirstRender.current = false;

    //        usersAPI.getValidMenus().then((data) => {
    //            if (data[0]) {
    //                console.log(data[0].isMobLeaveReq)
    //                setIsAllowLeaveReq(data[0].isMobLeaveReq);
    //                setIsAllowLeaveApproval(data[0].isMobLeaveApp)
    //                setIsAllowEmployeeLeaveDetails(data[0].isMobLeaveReq)
    //            }
    //        });
    //    }
    //}, [])

    const NavBar = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-1 col-sm-1 col-md-3 col-lg-2 col-xl-2 col-xxl-2"></div>
                        <div className="col-10 col-sm-10 col-md-6 col-lg-8 col-xl-8 col-xxl-8">


                           
                                <div className="row mt-5">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center">
                                        <Link to="/mainmenu/managementOperation">
                                            <div class="rounded rounded-5" style={{ width: 250 }}>
                                                <a type="button" className='border border-0 border-light rounded rounded-5'>
                                                    <img src={ManagementOperationImg} class="card-img-top rounded rounded-5" alt="CaseReceived" />
                                                </a>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center">
                                        <Link to="/mainmenu/managementFinance">
                                            <div class="rounded rounded-5" style={{ width: 250 }}>
                                                <a type="button" className='border border-0 border-light rounded rounded-5'>
                                                    <img src={FinanceImg} class="card-img-top rounded rounded-5" alt="CaseReceived" />
                                                </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            

                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <button type="button" className="form-control btn btn-danger" onClick={logOut}>Sign Out</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-3 col-lg-2 col-xl-2 col-xxl-2"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Routes>
                <Route path={"*"} element={<NavBar />}></Route>
                

                <Route path={"/managementOperation"} element={<ManagementOperation />}></Route>
                 <Route path={"/managementFinance"} element={<ManagementFinance />}></Route>

                
            </Routes>
        </>
    );
}