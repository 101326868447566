import CaseReceivedChart from '../../../chart/managementOperationChart/caseReceivedChart';
import SupplierOutstanding from '../../../../assets/images/SupplierOutstanding.png';


export default function SupplierOutstandingModal() {



    return (
        <>
            <a type="button" className='border border-0 rounded rounded-5' data-bs-toggle="modal" data-bs-target="#DeliveryPerson">
                <img src={SupplierOutstanding} class="card-img-top rounded rounded-5" alt="CaseReceived" />
            </a>


            <div class="modal fade" id="DeliveryPerson" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-sm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Delivery Person Performance</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body w-100 h-100 bg-light">
                            <div className="row">
                                <div className="col-0 col-sm-0 col-md-0 col-lg-9 col-xl-9 col-xxl-9"></div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="input-group input-group-sm">
                                        <input type="date" className="form-control" placeholder='To Date'/>
                                    </div>
                                </div>
                                <CaseReceivedChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        
        </>
    );
}